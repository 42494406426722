import React from 'react';
import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { SliderWithPartners, SliderWithTextBlocks } from '@commons/index';
import {
  BlocksOnHoverWithGradient,
  HeaderHome,
  BlogsAndResources,
  DropDownList,
  ToContactSection,
  Testimonials,
  CompanyInNumbers,
  NeedsSection,
  SalesEvents,
} from '@pages-impl/home';

import {
  SEOProps,
  headerProps,
  testimonialsProps,
  bigHeaderProps,
  companyInNumbersProps,
  needsSectionProps,
  sliderWithTextBlocksProps,
} from '@pages-impl/home/payload';

export default function HomePage(props) {
  return (
    <Layout {...props}>
      <SEO {...SEOProps} />
      <HeaderHome {...headerProps} />
      <SliderWithPartners title="Trusted by leaders" />
      <NeedsSection {...needsSectionProps} />
      <ToContactSection />
      <DropDownList />
      <NewStandardSection {...bigHeaderProps}> </NewStandardSection>
      <SliderWithTextBlocks {...sliderWithTextBlocksProps} />
      <Testimonials {...testimonialsProps} />
      <CompanyInNumbers {...companyInNumbersProps} />
      <BlogsAndResources />
      <BlocksOnHoverWithGradient />
      <SalesEvents />
    </Layout>
  );
}
